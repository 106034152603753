import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Appconstants } from 'src/app/shared/constants/app-constants';
import { HttpClient } from '@angular/common/http';
import { JavaServiceBase } from 'src/app/shared/base/javaservice-base';
import { catchError, map, share } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HeaderService extends JavaServiceBase {

  baseUrlStatic : string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingName;
  baseUrlDynamic : string = environment.javaServiceBaseUrl + Appconstants.javaServiceUsingUrl;
  model : string;
  data;
  observable;
  delegationSubject = new BehaviorSubject("");
  selectedUser = new Subject();
  dimensionType = new Subject();
  logoClick=new Subject();
  resetGenModel=new Subject();
  reloadGenModel=new Subject();
  reloadinboxaccordion=new Subject();
  reloadInboxView=new Subject();
  selectedTabIndex = new BehaviorSubject(0);//
  selectedTabIndex$ = this.selectedTabIndex.asObservable();
  refreshGenerichome=new Subject();//for refreshing generichome from genmodel
  reloadCardView22 = new Subject();
  blnSentenceCaseData: boolean = false;
  constructor(private httpClient: HttpClient) {
    super();
  }

  getValueForTypeAhead() {
    this.model = this.setModelForJavaService(Appconstants.valuefortypeahead, Appconstants.empty, Appconstants.empty, Appconstants.empty, Appconstants.get);
    console.log("getValueForTypeAhead");
    console.log(this.model,this.baseUrlStatic);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getContentForTypeAheadLaunchers() {
    this.model = this.setModelForJavaService(Appconstants.tapitswaggerlauncher, Appconstants.empty, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValueForPeopleSearch(txtSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.peoplesearch, txtSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValueForProjectSearch(txtProjectSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.projectsearch, txtProjectSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getValuesForLocationSearch(txtlocSearch: string) {
    this.model = this.setModelForJavaService(Appconstants.locationsearch, txtlocSearch, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  setSentenceCase(data: any){
    this.blnSentenceCaseData = data;
    console.log("in setSentence", data)
  }

  getSentenceCase(){
    console.log("in getSentence", this.blnSentenceCaseData)
    return this.blnSentenceCaseData;
  }


  getDetails(url: string, type: string) {
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {
      url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1];
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) {
      if(type.toLowerCase() == 'get'){
        return this.httpClient.get(url).pipe(catchError(() => throwError(new Error('error'))));;
      }
      else{
        return this.httpClient.post(url,Appconstants.empty).pipe(catchError(() => throwError(new Error('error'))));;
      }
    }
    else {
    this.model =this.setModelForJavaService(Appconstants.empty,Appconstants.empty,url,Appconstants.empty,type);
    console.log("getdetails");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic,this.model)
    .pipe(catchError(() => throwError(new Error('error'))));
  }
  } 

  getFormDetails(id: string) {
    this.model = this.setModelForJavaService(Appconstants.formdetails, id, Appconstants.empty, Appconstants.empty, Appconstants.get);
    console.log("getFormDetails");
    console.log(this.baseUrlStatic, this.model);
    return this.httpClient.post(this.baseUrlStatic, this.model).pipe(catchError(() => throwError(new Error('error'))));
  }



  getDetailsForGenModal() {
    const baseUrl = Appconstants.genModelPath;
    return this.httpClient.get(baseUrl);
  }

  getProjectDetails(txtProjCode: string) { 
    this.model = this.setModelForJavaService(Appconstants.projectcodedetails, txtProjCode, Appconstants.empty, Appconstants.empty, Appconstants.get);
    return this.httpClient.post(this.baseUrlStatic, this.model);
  }

  getDDdetails(url: string, queryString: string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url+ "?strLocation=" +queryString;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {
      url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1]+ "?strLocation=" +queryString;       
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) { 
      return this.httpClient.post(url, Appconstants.empty) 
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url+ "?strLocation=" +queryString, Appconstants.empty, Appconstants.post);
    console.log("getDDdetails");
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  getBoadringPoint(url: string, strLocation: string, strArea: string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url+ "?strLocation=" +strLocation +"&strArea="+strArea;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {
        url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1]+ "?strLocation=" +strLocation +"&strArea="+strArea;     
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) { 
      return this.httpClient.get(url)
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url+ "?strLocation=" +strLocation +"&strArea="+strArea, Appconstants.empty, Appconstants.post);
    console.log("getBoadringPoint");
    console.log(this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  

  postApplyOH(url: string, date: string){
    let requestBody:{applicantMailId: string, ohDate: string};
    let strBody: string;
    requestBody = {applicantMailId: "", ohDate: ""};
    requestBody.ohDate = date;
    strBody = JSON.stringify(requestBody);
    console.log("postApplyOH");
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {
        url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1];        
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) { 
        return this.httpClient.post(url,strBody) 
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url , strBody, Appconstants.post);
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  postCancelOH(url: string, LeaveFormId: string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url+ '?intLeaveFormId='+ LeaveFormId;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) { 
        url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1]+ '?intLeaveFormId='+ LeaveFormId;         
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) {
      return this.httpClient.post(url,Appconstants.empty)
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url + '?intLeaveFormId='+ LeaveFormId , Appconstants.empty, Appconstants.post);
    console.log("postCancelOH");
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  generateRecord(url: string, type: string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {
      url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1];
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) { 
      if(type.toLowerCase() == 'get'){
        return this.httpClient.get(url)
      }
      else{
        return this.httpClient.post(url,Appconstants.empty)
      }
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url, Appconstants.empty, type);
    console.log("generateRecord");
    console.log(this.baseUrlDynamic, this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  submitSUForm(url: string, suBody: string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {  
        url=environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1]; 
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) { 
        return this.httpClient.post(url,suBody)
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, suBody, Appconstants.post);
    console.log("submitSS");
    console.log(this.baseUrlDynamic,this.model)
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  getBetaUsersData(){    
    let url: string = "";
    if (this.data) {
      return of(this.data);
    } else if (this.observable) {
      return this.observable;
    }
    else{
      url= environment.benfitsUrl;
        this.observable = this.httpClient.get(url, {
          observe: 'response'
        }) .pipe(map(response =>  {
          this.data = response.body;
          return this.data;
        })  ,share());
        return this.observable;
      } 
  }

  submitNegativeHours(model:object, url:string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) {   
        url = environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1];      
    }
       if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) {
        return this.httpClient.post(url,JSON.stringify(model))
    }
    else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty, url, JSON.stringify(model), Appconstants.post);
    console.log("submitNegativeHours");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  // getUnifiedSearchResultForTypeahead(strSearchValue: string, benefitsId: string, blnPeopleSearh: boolean){
  //   let url: string;
  //   let val=''
  //   if(environment.isInternet.toLowerCase()=='internet'){
  //     val='web-internet'
  //   }
  //   else{
  //     val='web-intranet'
  //   }
  //   if (!benefitsId ||benefitsId==undefined || benefitsId.trim() === "") 
  //   { benefitsId = "*"; }
  //   let requestbody={
  //     "benefitIds":benefitsId,
  //     "queryText":strSearchValue,
  //     "source":val,
  //     "typeAheadType":"services,analytics,people,skill,content,solution,proposals,telemetrydata"
 
  //   };
  

  //     url = (blnPeopleSearh === true) ?
  //     environment.unisearchpeople :
  //     environment.unisearchall;

  //   if(url!=environment.unisearchpeople  && strSearchValue.startsWith("&")){
  //     let value=strSearchValue.split('&')[1]
  //     url = url.replace("{0}",'%26'+value);
  //   }
  //   if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
  //     url = environment.gatewayBaseUrl + url;
  //   }
  //   return this.httpClient.post(url, requestbody);
  // }
  getFeedbackForSearch(url:string){
    if(url !== undefined && url!== null &&url!== "" && !(url.startsWith("http"))){
      url = environment.gatewayBaseUrl + url;
    }
    if ((url.indexOf(environment.intranetGatewayBaseUrl) > -1 || url.indexOf(environment.internetGatewayBaseUrl) > -1)) { 
      url=environment.gatewayBaseUrlwithoutInfydigital + url.split('infosys.com')[1];                 
  }
     if (url.indexOf(environment.gatewayBaseUrlwithoutInfydigital) > -1 || (url.indexOf(environment.internetUrl) > -1)) {
      return this.httpClient.get(url)
  }
  else {
    this.model = this.setModelForJavaService(Appconstants.empty, Appconstants.empty,url, Appconstants.empty, Appconstants.get);
    console.log("Feedback send");
    console.log(this.baseUrlDynamic, this.model);
    return this.httpClient.post(this.baseUrlDynamic, this.model);
  }
  }

  //sidebar profile
// getProfileImage(){
//   let url = environment.profilePicture;
//   return this.httpClient.post(url,{}).pipe(catchError(() => throwError(new Error('error'))));
// }

//  getEmployeeMiscDetails(){
//      let url = environment.employeeMiscDetails;
//      return this.httpClient.get(url)
//  }

// getEmployeeOffcialInfo(){
//   console.log("getEmployeeOffcialInfo");
//   let url = environment.employeeOffcialInfo;       
//   return this.httpClient.get(url)
// }

// getDelegationAccess(){
//     let url = environment.getDelegationAccess;
//     return this.httpClient.get(url)     
// }

// getClickData(){
//   let url=environment.trendingSearch;
//   if(url !== undefined && url!== null &&url!== ""){
//   return this.httpClient.get(url)}
// } 

  getMaintenanceJson(url):any{
    return this.httpClient.get(url);
  }
}
